const hostname = window.location.hostname;

const api = {
  localhost: "https://rrpb.margham.app/",
  platform: "https://rrpb.margham.app/"
};

let apiBase = "";
if (hostname === "localhost") {
  apiBase = api.platform;
} else {
  apiBase = api.platform;
}
export default apiBase;
